export default function NFTSimpleIcon() {
  return (
    <svg
      width="70px"
      height="70px"
      fill="white"
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <symbol id="f" overflow="visible">
          <path d="m39.078-2.3281c-2.0117 1.043-4.1094 1.8281-6.2969 2.3594s-4.4648 0.79688-6.8281 0.79688c-7.0859 0-12.699-1.9766-16.844-5.9375-4.1367-3.957-6.2031-9.3281-6.2031-16.109 0-6.7891 2.0664-12.164 6.2031-16.125 4.1445-3.9688 9.7578-5.9531 16.844-5.9531 2.3633 0 4.6406 0.26562 6.8281 0.79688s4.2852 1.3242 6.2969 2.375v8.7969c-2.0312-1.3828-4.0391-2.3984-6.0156-3.0469-1.9688-0.64453-4.0469-0.96875-6.2344-0.96875-3.9062 0-6.9844 1.2578-9.2344 3.7656-2.2422 2.5-3.3594 5.9531-3.3594 10.359 0 4.3867 1.1172 7.8359 3.3594 10.344 2.25 2.5 5.3281 3.75 9.2344 3.75 2.1875 0 4.2656-0.32031 6.2344-0.96875 1.9766-0.64453 3.9844-1.6602 6.0156-3.0469z" />
        </symbol>
        <symbol id="e" overflow="visible">
          <path d="m16.031-40.953v9.0625h10.516v7.2812h-10.516v13.531c0 1.4805 0.28906 2.4805 0.875 3 0.59375 0.52344 1.7656 0.78125 3.5156 0.78125h5.2344v7.2969h-8.7344c-4.0312 0-6.8867-0.83594-8.5625-2.5156-1.6797-1.6875-2.5156-4.5391-2.5156-8.5625v-13.531h-5.0781v-7.2812h5.0781v-9.0625z" />
        </symbol>
        <symbol id="a" overflow="visible">
          <path d="m26.594-27.234v-17.078h10.266v44.312h-10.266v-4.6094c-1.3984 1.875-2.9453 3.25-4.6406 4.125-1.6875 0.875-3.6406 1.3125-5.8594 1.3125-3.9297 0-7.1562-1.5625-9.6875-4.6875-2.5234-3.125-3.7812-7.1445-3.7812-12.062 0-4.9141 1.2578-8.9375 3.7812-12.062 2.5312-3.125 5.7578-4.6875 9.6875-4.6875 2.1953 0 4.1445 0.44531 5.8438 1.3281 1.707 0.88672 3.2578 2.2578 4.6562 4.1094zm-6.7188 20.656c2.1875 0 3.8516-0.79688 5-2.3906 1.1445-1.5938 1.7188-3.9102 1.7188-6.9531 0-3.0391-0.57422-5.3594-1.7188-6.9531-1.1484-1.5938-2.8125-2.3906-5-2.3906-2.168 0-3.8242 0.79688-4.9688 2.3906-1.1484 1.5938-1.7188 3.9141-1.7188 6.9531 0 3.043 0.57031 5.3594 1.7188 6.9531 1.1445 1.5938 2.8008 2.3906 4.9688 2.3906z" />
        </symbol>
        <symbol id="d" overflow="visible">
          <path d="m21.875-6.5781c2.1758 0 3.8359-0.79688 4.9844-2.3906 1.1562-1.5938 1.7344-3.9102 1.7344-6.9531 0-3.0391-0.57812-5.3594-1.7344-6.9531-1.1484-1.5938-2.8086-2.3906-4.9844-2.3906-2.1875 0-3.8672 0.80469-5.0312 2.4062-1.168 1.6055-1.75 3.918-1.75 6.9375 0 3.0234 0.58203 5.3359 1.75 6.9375 1.1641 1.6055 2.8438 2.4062 5.0312 2.4062zm-6.7812-20.656c1.4062-1.8516 2.9609-3.2227 4.6719-4.1094 1.707-0.88281 3.6719-1.3281 5.8906-1.3281 3.9375 0 7.1641 1.5625 9.6875 4.6875 2.5195 3.125 3.7812 7.1484 3.7812 12.062 0 4.918-1.2617 8.9375-3.7812 12.062-2.5234 3.125-5.75 4.6875-9.6875 4.6875-2.2188 0-4.1836-0.44531-5.8906-1.3281-1.7109-0.88281-3.2656-2.2539-4.6719-4.1094v4.6094h-10.188v-44.312h10.188z" />
        </symbol>
        <symbol id="c" overflow="visible">
          <path d="m31.156-7.75h-17.141l-2.7031 7.75h-11.031l15.75-42.516h13.078l15.75 42.516h-11.031zm-14.406-7.8906h11.641l-5.8125-16.906z" />
        </symbol>
        <symbol id="b" overflow="visible">
          <path d="m4.9062-31.891h10.188v31.891h-10.188zm0-12.422h10.188v8.3125h-10.188z" />
        </symbol>
      </defs>
      <g transform="translate(10px, 69px)">
        <path
          d="m466.67 268.33c-70.828 0-128.33 57.504-128.33 128.33 0 70.828 57.504 128.33 128.33 128.33s128.33-57.504 128.33-128.33c0-70.828-57.504-128.33-128.33-128.33zm0 23.332c57.949 0 105 47.051 105 105 0 57.949-47.051 105-105 105s-105-47.051-105-105c0-57.949 47.051-105 105-105zm5.9961 25c-3.6875-2.2266-8.3086-2.2266-11.992 0l-58.332 35c-3.5234 2.1016-5.6719 5.9023-5.6719 10v70c0 4.0938 2.1484 7.8984 5.6719 10l58.332 35c3.6875 2.2266 8.3086 2.2266 11.992 0l58.332-35c3.5234-2.1016 5.6719-5.9023 5.6719-10v-70c0-4.0938-2.1484-7.8984-5.6719-10zm-344.33 3.1602 81.668-81.668 85.086 85.086c4.5508 4.5508 11.945 4.5508 16.496 0 4.5508-4.5508 4.5508-11.945 0-16.496l-38.418-38.418 100.17-100.17s73.418 73.418 73.418 73.418c4.5508 4.5508 11.945 4.5508 16.496 0 4.5508-4.5508 4.5508-11.945 0-16.496l-81.668-81.668c-4.5508-4.5625-11.945-4.5625-16.496 0l-108.42 108.42-38.418-38.418c-4.5508-4.5625-11.945-4.5625-16.496 0l-73.418 73.418v-216.84c0-6.4414 5.2266-11.668 11.668-11.668h350c6.4414 0 11.668 5.2266 11.668 11.668v163.33c0 6.4414 5.2266 11.668 11.668 11.668s11.668-5.2266 11.668-11.668v-163.33c0-19.32-15.68-35-35-35h-350c-19.32 0-35 15.68-35 35v280c0 19.32 15.68 35 35 35h175c6.4414 0 11.668-5.2266 11.668-11.668 0-6.4414-5.2266-11.668-11.668-11.668h-175c-6.4414 0-11.668-5.2266-11.668-11.668v-30.172zm93.332-226.5c-25.762 0-46.668 20.906-46.668 46.668 0 25.762 20.906 46.668 46.668 46.668s46.668-20.906 46.668-46.668c0-25.762-20.906-46.668-46.668-46.668z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
